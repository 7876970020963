import React, {useEffect} from 'react';

import { toast } from 'react-toastify';
import { ReactComponent as NoticeIcon } from "../../../gcs_image/common/normal-notice-icon.svg";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from 'react-redux';

function NormalNotice(props) {
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }))

    useEffect(() => {
        if (!!socket && socket.message.length > 0) {
            socket.message.map((msg) => {
                if (msg.severity === 3) {               // NORMAL
                    toast.info(`${socket.getDroneName(msg.drone_serial_num)} : ${msg.message}`, {
                        // position: toast.POSITION.TOP_CENTER,
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#97FF11"} />
                    })
                }
            })
        }
    }, [socket.message])

    return;
}

export default NormalNotice;
import React from 'react';
import styled from 'styled-components';

import EmergencyNotice from './notice/EmergencyNotice';
import WarningNotice from "./notice/WarningNotice";
import NormalNotice from "./notice/NormalNotice";
import { ToastContainer, Slide, Zoom } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import FireNotice from './notice/FireNotice';
import AccidentNotice from './notice/AccidentNotice';
const S = {
    Wrap: styled.div`
        position : fixed;
        left: 30%;
        top: 88px;
        transform: translate(-50%, 0);
        z-index: 99;
        /* max-height: 224px;
        overflow: scroll; */
        /* background-color: skyblue; */
    `,
    StyledComponentWrap: styled.div`
        position: relative;
    `,
    StyledContainer: styled(ToastContainer)`
        width: 100%;
        .Toastify__toast-container {
            position: relative;
            width: 800px;
            /* display: flex;
            justify-content: center;
            align-items: center; */
        }
        .Toastify__toast {
            width: 800px;
            /* height: 44px; */
            padding: 6px 16px;
            border-radius: 2px;
            border: 1px solid #1E040A;
            box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.42);
            font-family: Poppins;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            min-height: 44px;
            margin-bottom: 16px;
        }
        .Toastify__toast-theme--colored.Toastify__toast--error {
            background-color: #680216;
            color: var(--dark-theme-rest-foreground-red-foreground, #F75E75);
        }
        .Toastify__toast-theme--colored.Toastify__toast--warning {
            background-color: #FFDC60;
            border: 1px solid #E9BA13;
            border-radius: 4px;
            color: #242424;
        }
        .Toastify__toast-theme--colored.Toastify__toast--info {
            background-color: #F5F5F5;
            border: 1px solid #D1D1D1;
            border-radius: 4px;
            color: #242424;
        }

        .Toastify__toast-theme--colored.Toastify__toast--success {
            background-color: #01256C;
            border: 1px solid #021843;
            color: #4AE9FF;
        }

        .Toastify__toast--CliticalNotice {
            background-color: #FF6060;
            border: 1px solid #AF293E;
            border-radius: 4px;
            color:#fff
        }

        .Toastify__toast-container--top-center {
            top: 0px;
        }
    `
}

function NoticeView(props) {
    return (
        <S.Wrap>

            {/* <EmergencyNotice /> */}

            <S.StyledComponentWrap>
                <S.StyledContainer
                    position="top-right"
                    transition={Zoom}
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop
                    closeButton={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                    theme="colored"
                    />
            </S.StyledComponentWrap>

            <EmergencyNotice />
            <WarningNotice />
            <NormalNotice />
            <FireNotice />
            <AccidentNotice />
        </S.Wrap>
    );
}

export default NoticeView;
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ReactComponent as NoticeIcon } from "../../../gcs_image/common/warning-notice-icon.svg";
import "react-toastify/dist/ReactToastify.css";

function WarningNotice(props) {
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }))

    useEffect(() => {
        if (!!socket && socket.message.length > 0) {
            socket.message.map((msg) => {
                if (msg.severity === 2) {               // WARNING
                    toast.warn(`${socket.getDroneName(msg.drone_serial_num)} : ${msg.message}`, {
                        // position: toast.POSITION.TOP_CENTER,
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#FFDE0D"} />
                    })
                }
            })
        }
    }, [socket.message])

    return;
}

export default WarningNotice;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, {keyframes} from "styled-components";

import Map from "../map/Map";
import ConnectServer from "../connect/ConnectServer";
import TopMenubar from "../view/TopMenubar";
import NoticeView from "../view/NoticeView";
import DroneListView from "../view/DroneListView";
import CameraView from "../view/CameraView";
import BottomMenubar from "../view/BottomMenubar";
import Weather from "../view/Weather/Wether";
import FilreReportView from "../view/FilreReportView";
import Prediction from "../view/Prediction/Prediction";
import FireMissionButton from "../view/forest-fire-report/FireMissionButton";
import OrderListView from "../view/OrderListView";

import { setFireReportWarning, setAccidentReportWarning } from "../../store/reducer/skycook";
import ReportImageModal from "../view/forest-fire-report/modal/ReportImageModal";
import DetectImage from "../view/forest-fire-report/DetectImage";

const changeSize = keyframes`
  0% {
    width: 231px;
    height: 231px;
  }
  50% {
    width: 269px;
    height: 269px;
  }
  100% {
    width: 231px;
    height: 231px;
  }
`;

const S = {
    Wrap: styled.div`
        width: 100vw;
        height: 100vh;
    `,

    ImageWrap : styled.div`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 99;
        cursor: pointer;
    `,

    WarningBlock : styled.div`
        width: 100%;
        height: 269px;
        display: flex;
        justify-content: center;
        align-items: center;
    `,

    ImageViewBlock : styled.div`
        position: absolute;
        overflow: hidden;
        border-radius: 50%;
        animation: ${changeSize} 1s infinite linear;
    `,
    WarningIMG : styled.img`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `,
};

const R = {
    Wrap: styled.div`
      top: 72px;
      right: 30px;
      position: fixed;
      z-index: 99;
      
  `,
  
  sub: styled.div`
    display: flex;
    flex-direction: column;
    gap:20px;
    width: 390px;
    height: 100%;
  `,
}

function Gcs() {
  const [data, setData] = useState([]);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [selectedDroneSN, setSelectedDroneSN] = useState("");
  const [selectedOrderList, setSelectedOrderList] = useState({
    droneSN: "",
    orderList: [],
  });
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoFoldState, setVideoFoldState] = useState("open");     // fold / open / extend
  const [step, setStep] = useState(0);

  const {
    map,
    forest_fire_report,
    delivery_status,
    fire_report_warning,
    accident_report_warning,
    is_drone_detect,
  } = useSelector((state) => ({
    map: state.map.map,
    forest_fire_report: state.skycook.forest_fire_report,
    delivery_status: state.skycook.delivery_status,
    fire_report_warning: state.skycook.fire_report_warning,
    accident_report_warning: state.skycook.accident_report_warning,
    is_drone_detect: state.video.is_drone_detect,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (map && isMapLoading) {
      setIsMapLoading(false);
    }
  }, [map, isMapLoading]);

  const isObjectEmpty = (obj) => Object.keys(obj).length === 0;
  
  const handleWarningClick = (type) => {
    if (type === "fire") {
      dispatch(setFireReportWarning(false));
    } else {
      dispatch(setAccidentReportWarning(false));
    }
  };

  return (
    <>
      <Map />
      <S.Wrap>
        {!isMapLoading && <ConnectServer setSelectedDroneSN={setSelectedDroneSN} />}
        <TopMenubar />
        <NoticeView />
        <DroneListView
          selectedOrderList={selectedOrderList}
          setSelectedOrderList={setSelectedOrderList}
          selectedDroneSN={selectedDroneSN}
          setSelectedDroneSN={setSelectedDroneSN}
        />
        {!delivery_status ? (
          <>
            <R.Wrap>
              <R.sub>
                <Weather data={data} setData={setData} isfold={isObjectEmpty(forest_fire_report)} step={step}/>
                {!isObjectEmpty(forest_fire_report) && !fire_report_warning && !accident_report_warning && step === 0 && (
                  <>
                    <FilreReportView setIsModalOpen={setIsModalOpen} />
                  </>
                )}
                {(!isObjectEmpty(forest_fire_report) || is_drone_detect) && !fire_report_warning && !accident_report_warning && (
                  <>
                    <Prediction data={data} step={step} setStep={setStep} />
                  </>
                )}
              </R.sub>
            </R.Wrap>
            {!isObjectEmpty(forest_fire_report) && !fire_report_warning && !accident_report_warning && (
              <FireMissionButton />
            )}
          </>
        ) : (
          <OrderListView
            selectedDroneSN={selectedDroneSN}
            selectedOrderList={selectedOrderList}
            setSelectedOrderList={setSelectedOrderList}
          />
        )}
        <CameraView selectedDroneSN={selectedDroneSN} videoFoldState={videoFoldState} setVideoFoldState={setVideoFoldState}/>
        <DetectImage/>
        <ReportImageModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen} />
        <BottomMenubar />
      </S.Wrap>
    </>
  );
}

export default Gcs;
import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ReactComponent as NoticeIcon } from "../../../gcs_image/common/critical-notice-icon.svg";

const S = {
    Wrap: styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
        /* background-color: skyblue; */

        div {
            width: 400px;
            height: 44px;
            padding: 6px 16px;
            border-radius: 2px;
            box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.42);
            display: flex;
            align-items: center;
            gap: 7px;

            p {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
            }
        }
    `,
    CriticalNotice: styled.div`
        border: 1px solid #1E040A;
        background-color: #680216;

        p {
            color: var(--dark-theme-rest-foreground-red-foreground, #F75E75);
        }
    `,
}

function EmergencyNotice(props) {
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }))

    useEffect(() => {
        if (!!socket && socket.message.length > 0) {
            socket.message.map((msg) => {
                if (msg.severity === 1) {               // EMERGENCY
                    toast(`${socket.getDroneName(msg.drone_serial_num)} : ${msg.message}`, {
                        // position: toast.POSITION.TOP_CENTER,
                        className: 'Toastify__toast--CliticalNotice', // 커스텀 클래스 지정
                        icon: ({theme, type}) => <NoticeIcon width={"20px"} height={"20px"} fill={"#F75E75"} />
                    })
                }
            })
        }
    }, [socket.message])

    return;
}

export default EmergencyNotice;
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as NoticeIcon } from "../../../gcs_image/common/critical-notice-icon.svg";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from 'react-redux';
import { setFireReportWarning } from '../../../store/reducer/skycook';

function FireNotice(props) {
    const { fire_report_warning } = useSelector((state) => ({
        fire_report_warning: state.skycook.fire_report_warning,
    }));

    const dispatch = useDispatch();

    const handleConfirmClick = () => {
        dispatch(setFireReportWarning(false));
        toast.dismiss(); // 알림 닫기
    };

    useEffect(() => {
        if (fire_report_warning) {
            toast(`산불이 발생하였습니다. 산불 발생 위치를 확인해주세요.`, {
                icon: () => <NoticeIcon width="20px" height="20px" fill="#FFC56F" />,
                autoClose: false, // 자동으로 닫히지 않도록 설정
                className: 'Toastify__toast--CliticalNotice', // 커스텀 클래스 지정
                closeButton: ({ closeToast }) => (
                    <button onClick={() => { handleConfirmClick(); closeToast(); }} 
                        style={{ 
                            background: "none", 
                            border: "none", 
                            color: "#FFFFFF" 
                        }}
                    >
                        확인
                    </button>
                ),
            });
        }
    }, [fire_report_warning]);

    return <></>;
}

export default FireNotice;